@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    background-color: white;
    
    
}
.herologo {
    background-image: url('https://images.unsplash.com/photo-1610375461246-83df859d849d?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
}
